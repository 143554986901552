<template>
<router-view/>
</template>
<script>

export default {
  name: "supporter",
  components: {},
  data() {
    return {}
  },
  methods: {
    isMobile() {
      if( screen.width <= 760 ) {
          return true;
      }
      else {
          return false;
      }
    },
  },
  mounted() {
    /*if (this.isMobile()) {
      this.$router.push('/mobile');
    } else {
      this.$router.push('/');
    }*/


  },
  unmounted() {
  },
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,500;8..144,600;8..144,700;8..144,900&display=swap');
</style>
