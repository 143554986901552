import {defineStore} from "pinia";
import jwt_decode from "jwt-decode";
import userApi from "../api/users";

const baseURL = process.env.VUE_APP_API_URL;

export const useUserStore = defineStore("token", {
    state: () => ({
        //user: JSON.parse(localStorage.getItem("user")),
        //token: localStorage.getItem("token"),
        token: $cookies.get("token"),
        socketId: null,
        returnUrl: null,
        userData: null,
        userCurrentPoint: null,
        desktop: false,
    }),

    getters: {
        //isAuthorize:(state) => state.token && state.user ? true : false
        isAuthorize: (state) => state.token ? true : false
    },
    actions: {
        setDesktop(value) {
            this.desktop = value;
        },
        async getAccounts() {
            if (!this.accounts) {
                let res = await userApi.getAccounts();
                if (res?.length) {
                    this.setAccounts(res);
                    this.setCurrentAccountId(res[0].id)
                }
            }
        },
        setToken(token) {
            this.token = token;
            localStorage.setItem("token", token);
            $cookies.set("token", token);
        },
        setAccounts(data) {
            this.accounts = data;
            localStorage.setItem("accounts", JSON.stringify(data));
        },
        getToken() {
            return this.token;
        },
        setUser(data) {
            this.userData = data;
            localStorage.setItem("user", JSON.stringify(data));
        },
        async loginMobile(jwt) {
            if (this.desktop) {
                console.log('loginDesktop')
            } else {
                console.log("loginMobile")
            }
            
            //this.setUser(_user);
            this.setToken(jwt);
            if (this.returnUrl) {
                this.router.push(this.returnUrl);
            } else {
                if (!this.desktop) {
                    this.router.push("/mobile");
                } else {
                    return true
                    //this.router.push({ name: "stores"});
                }
            }
        },
        logoutMobile() {
            console.log("logoutMobile")
            this.userData = null;
            this.token = null;
            localStorage.removeItem("token");
            localStorage.removeItem("accounts");
            $cookies.remove("token");
            
            if (!this.desktop) {
                this.router.push("/mobile/login");
            } else {
                this.router.push({name: "signDesk"});
            }
        },

        getUserData() {
            console.log("getUserData")
            return userApi.getUserProfile().then(response => {
                this.userData = response;
                console.log("getUserData", this.userData)
                this.userCurrentPoint = response.current_store.id;
            }).catch(e => {
                console.error(e);
                this.tasks = [];
            });
        },

        setUserCurrentPoint(data) {
            return userApi.setCurrentPoint(data).then(response => {
                this.userCurrentPoint = data.store_id;
            }).catch(e => {
                console.error(e);
                this.comments = [];
            });

        }

    },
});
