import {sendRequest} from "@/helpers";

export default {
    async getCurrentUser(id) {
        return await sendRequest(`/auth/user/${id}`, "get", "");
    },
    async getUserProfile() {
        return await sendRequest(`/api/v1/user_data/profile/`, "get", "");
    },
    async setCurrentPoint(data) {
        return await sendRequest(`/api/v1/user_data/current_store/`,
            "post",
            data);
    },
    async getAccounts() {
        let res = await sendRequest("/api/v1/user/", "get", "");
        return res;
    },

};
