import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from "@/stores/userStore";

const routes = [
  {
    path: "/",
    component: () => import('@/desktop/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'signDesk',
        component: () => import('@/desktop/views/signIn.vue')
      },
      {
        path: '/registr',
        name: 'registrnDesk',
        component: () => import('@/desktop/views/registr.vue')
      },
      {
        path: '/remind',
        name: 'remindPassDesk',
        component: () => import('@/desktop/views/remind.vue')
      },
      {
        path: '/stores',
        name: 'stores',
        component: () => import('@/desktop/views/home.vue')
      },
      {
        path: '/creatingPoint',
        name: 'creatingPoint',
        component: () => import('@/desktop/views/crtPoint.vue')
      },
      {
        path: '/workaround/',
        name: 'workaroundLayout',
        component: () => import('@/desktop/views/menuLayout.vue'),
        children: [
          {
            path: 'tasks',
            name: 'tasks',
            component: () => import('@/desktop/views/tasks.vue')
          },
          {
            path: 'problems',
            name: 'problem',
            component: () => import('@/desktop/views/problems.vue')
          },
          {
            path: 'shedule',
            name: 'shedule',
            component: () => import('@/desktop/views/shedule.vue')
          },
          {
            path: 'crew',
            name: 'crew',
            component: () => import('@/desktop/views/crew.vue')
          },
          {
            path: 'report',
            name: 'report',
            component: () => import('@/desktop/views/report.vue')
          },
          {
            path: 'settings',
            name: 'settings',
            component: () => import('@/desktop/views/settings.vue')
          },
          {
            path: 'cameras',
            name: 'cameras',
            component: () => import('@/desktop/views/cameras.vue')
          },
          
        ]
      },
    ],
  },

  {
    path: "/mobile",
    component: () => import('../mobile/Layout.vue'),
    children: [
      {
        path: '/mobile/:id?',
        name: 'mobile',
        component: () => import('../mobile/views/Dashboard.vue'),
        props: route => ({ id: route.params.id })
      },
      {
        path: '/mobile/problems',
        name: 'problems',
        component: () => import('../mobile/views/Problems.vue')
      },
      {
        path: '/mobile/calendar',
        name: 'calendar',
        component: () => import('../mobile/views/Calendar.vue')
      },
      {
        path: '/mobile/chat/:id',
        name: 'chat',
        component: () => import('../mobile/views/Chat.vue'),
        props: route => ({ id: route.params.id })
      },
      {
        path: '/sing-in',
        name: 'singIn',
        component: () => import('../views/SingIn.vue')
      },
      {
        path: '/remind-password',
        name: 'remindPassword',
        component: () => import('../views/RemindPassword.vue')
      },
      {
        path: '/registration',
        name: 'registration',
        component: () => import('../views/Registration.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      },
      {
        path: '/mobile/menu',
        name: 'menu',
        component: () => import('../mobile/views/Menu.vue')
      },
      {
        path: '/mobile/profile',
        name: 'profile',
        component: () => import('../mobile/views/Profile.vue')
      },
      {
        path: '/mobile/login',
        name: 'singIn',
        component: () => import('../mobile/views/SingIn.vue')
      },
      {
        path: '/mobile/registration',
        name: 'registrationMobile',
        component: () => import('../mobile/views/Registration.vue')
      },
      {
        path: '/mobile/remind-password',
        name: 'remindPasswordMobile',
        component: () => import('../mobile/views/RemindPassword.vue')
      }
    ]
  },
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {

/*  const publicPages = ['/mobile/login', '/mobile/registration', '/mobile/remind-password'];
  const authRequired = !publicPages.includes(to.path);
  const userStore = useUserStore();

  console.log('authRequired', authRequired)
  console.log('userStore', userStore)
  if (authRequired && !userStore.token) {
    userStore.returnUrl = to.fullPath;
    return '/mobile/login';
  }*/
});
export default router
