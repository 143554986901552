import { createApp, markRaw } from 'vue'
import App from './App.vue'
import { createPinia } from "pinia";
import router from './router'
import store from './stores'
import VueCookies from 'vue-cookies'
import VueAxios from "vue-axios";
import axios from "axios";
import { io } from "socket.io-client";
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap";
import "@/assets/css/animate.css"

import jQuery from "jquery"; const $ = jQuery; window.$ = $;
import 'bootstrap/dist/js/bootstrap';

const pinia = createPinia();
pinia.use(({ store }) => {
    store.router = markRaw(router);
});

export const app = createApp(App);

app.use(pinia)
    .use(store)
    .use(VueCookies, { expires: '7d'})
    .use(VueAxios, axios)
    .use(router);

    import { useUserStore } from "@/stores/userStore";
    const userStore = useUserStore();
    
    const socket = io(process.env.VUE_APP_API_URL, {
            auth: {token: userStore?.token}
        });
    
    socket.on("connect", () => {
        console.log("Connected", socket);
    });

    

app.use(socket);

app.config.globalProperties.$soketio = socket;

app.mount('#app')