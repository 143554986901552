import { useUserStore } from "@/stores/userStore";
import axios from "axios";

const axiosInstanсe = axios.create();
const baseURL = process.env.VUE_APP_API_URL;

async function refreshAccessToken() {
  let res = null;
  await axios
    .put(
      `${baseURL}auth/refresh`,
      {},
      { withCredentials: true }
    )
    .then((refreshResponse) => {
      const userStore = useUserStore();
      userStore.setToken(refreshResponse.data.accessToken);
      res = refreshResponse.data.accessToken;
    })
    .catch(function (error) {
      const userStore = useUserStore();
      userStore.logoutMobile();
      if (error.response) {       
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {        
        console.log(error.request);
      } else {       
        console.log('Error', error.message);
      }     
    });    
  return res;
}

axiosInstanсe.interceptors.request.use(function (config) {
  const userStore = useUserStore();

  if (userStore.token && userStore.token.length > 0) {
    config.headers['Authorization'] = "Bearer " + userStore.token;
  }

  return config;
});

axiosInstanсe.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const access_token = await refreshAccessToken();

      originalRequest.headers["x-access-token"] = access_token;

      return axiosInstanсe(originalRequest);
    }
    return Promise.reject(error);
  }
);
export async function sendRequest(url, methods, req, headers = null) {
  const userStore = useUserStore();
  
  axiosInstanсe.defaults.baseURL = baseURL;
  axiosInstanсe.defaults.headers.common["Cache-Control"] = "no-cache";
  axiosInstanсe.defaults.withCredentials = true;
  if (!headers){
     axiosInstanсe.defaults.headers.post["Content-Type"] =
    "application/json; charset=utf-8";
  }
  else {
    axiosInstanсe.defaults.headers.put["Content-Type"] = headers
  }

  if(methods.toUpperCase() === 'GET' && req) {
    url = url + '?q=' + JSON.stringify(req);
    req = null;
  }
  
  return axiosInstanсe[methods](url, req, { interceptNetworkError: true }).then(
      (response) => {
        return response.data;
      }
    );
}


export async function sendRequestFile(url, methods, formData, headers = null) {

  axiosInstanсe.defaults.baseURL = baseURL;
  axiosInstanсe.defaults.headers.common["Cache-Control"] = "no-cache";
  axiosInstanсe.defaults.withCredentials = true;
  axiosInstanсe.defaults.headers.post["Content-Type"] = headers

  return axiosInstanсe[methods](url, formData, { interceptNetworkError: true }).then(
      (response) => {
        console.log("sendRequestFile", response)
        return response.data;
      }
  );
}